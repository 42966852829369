@tailwind base;
@tailwind components;
@tailwind utilities;






.hoverOnWhite :hover {
  color: aqua !important;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* ag grid */
.ag-grid-table {
  --ag-borders: none;
  --ag-header-background-color: #E5ECF680;


  --ag-row-border-width: 1px;
  --ag-row-border-color: #241c1c0d;
  --ag-row-hover-color: none;
}








body {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Standard syntax */
}



/* Hide the scrollbar in webkit browsers (Chrome, Safari) */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide the scrollbar in Firefox */
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.onHoverBlack5:hover {
  background-color: #1C1C1C0D;
}